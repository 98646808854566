/**
 * 
 * Gets current filtered Sailing Schedule table,
 * Gets Seabridge Logo from page - Mobile Logo in this case, as the normal one is white.
 * Write contents to windows for print. 
 * 
 */
function printSection() {
	var prtContent = document.getElementById('schedule-table').innerHTML;
	var seabridgeLogo = document.getElementsByClassName('mobile-navigation__logo')[0].innerHTML;

	var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

	WinPrint.document.write('<html><head><title>Sailing Schedule</title>');
	WinPrint.document.write('</head><body>');
	WinPrint.document.write('<div style="padding: 2rem;">');
	WinPrint.document.write(seabridgeLogo);
	WinPrint.document.write('</div>');
	WinPrint.document.write(prtContent);
	WinPrint.document.write('</div></html>');
	WinPrint.document.close();
	// WinPrint.focus();
	WinPrint.print();
}

function formatDate(date) {
	var d = date.split("=");
	var d = d[0].split("/");
	var d = d[2]+'-'+d[1]+'-'+d[0];
	return d;
}

function disableUtil() {
	var disablethings = document.querySelectorAll('.disable-this');

	for (var index = 0; index < disablethings.length; index++) {
		var element = disablethings[index];

		var k = element.querySelector('.gfield_select');

		k.disabled = true;
		
	}
	return true;
}

function sailingScheduleInputs() {
	var load = "load="+document.getElementById('load-port-select').value;
	var dest = "dest="+document.getElementById('destination-select').value;
	var date = "date="+document.getElementById('schedule-date').value;
	var array = [load,dest,date];

	array = array.filter(function(value, index, arr){
		return value.length > 5;
	});
	return array;
}

// Sailing Schedule
function loadPortSelect() {
	console.log("On change event - #load-port-select - clicked");
	var data = sailingScheduleInputs();
	showHideAction(data);
}

function destinationSelect() {
	console.log("On change event - #destination-select - clicked");
	var data = sailingScheduleInputs();
	showHideAction(data);
}

function scheduleDateSelect() {
	console.log("On change event - #schedule-date - clicked");
	var data = sailingScheduleInputs();
	showHideAction(data);
}
// Sailing Schedule -- END

function onloadFilter() {
	var url = new URL(window.location.href);
	url = url.toString();
	urlSplit = url.split('?');
	if( typeof urlSplit[1] !== 'undefined') {
		urlSplit = urlSplit[1].split("&"); 
		showHideAction(urlSplit);
	} else {
		$('#schedules-container tr').each(function(e) {
			$(this).hide();
		});
	}
}

function showHideAction(data) {
	// console.log(data);
	var load = '';
	var dest = '';
	var date = '';

	for (var index = 0; index < data.length; index++) {
		var element = data[index].split("=");
		// console.log( element[0] );
		if( element[0] == 'dest') {
			var dest = element[0];
		}
		if( element[0] == 'load') {
			var load = element[0];
		}
		if( element[0] == 'date') {
			var date = element[0];
		}
	}

	var mode = load+dest+date;
	console.log(mode);
	
	$('#schedules-container').children('tr').each( function(e) {
		$(this).hide();
	});

	var table = document.querySelector('#schedules-container');

	switch (mode) {
		case 'load':
			var loader = data[0].split('=');
			var value = 'load-port-'+loader[1].toString().toLowerCase();
			for (var i = 0; i < table.children.length; i++) {
				if( table.children[i].className.includes(value) ) {
					table.children[i].style.display = 'table-row';
				}
			}
		break;
		case 'dest':
			var loader = data[0].split('=');
			var value = 'destination-port-'+loader[1].toString().toLowerCase();
			for (var i = 0; i < table.children.length; i++) {
				if( table.children[i].className.includes(value) ) {
					table.children[i].style.display = 'table-row';
				}
			}
		break;
		case 'date':
				var loader = data[0].split('=');
				var date = new Date(formatDate(loader[1]));
				for (var i = 0; i < table.children.length; i++) {
					// console.log( formatDate(table.children[i].dataset.date) );
					var itemDate = new Date( formatDate(table.children[i].dataset.date) );
					if(  itemDate >= date ) {
						table.children[i].style.display = 'table-row';
					}
				}

			
		break;
		case 'loaddate':
				// load
				var load = data[0].split('=');
				var date = data[1].split('=')
				var date = new Date(formatDate(date[1]));
				var value = 'load-port-'+load[1].toString().toLowerCase();
				for (var i = 0; i < table.children.length; i++) {
					var itemDate = new Date( formatDate(table.children[i].dataset.date) );
					if( table.children[i].className.includes(value) & itemDate >= date) {
						table.children[i].style.display = 'table-row';
					}
				}

		break;
		case 'loaddest':

				var loader1 = data[0].split('=');
				var loader2 = data[1].split('=');

				var value1 = 'load-port-'+loader1[1].toString().toLowerCase();
				var value2 = 'destination-port-'+loader2[1].toString().toLowerCase();
				for (var i = 0; i < table.children.length; i++) {
					if( table.children[i].className.includes(value1) & table.children[i].className.includes(value2)) {
						table.children[i].style.display = 'table-row';
					}    
				}
		break;
		case 'loaddestdate':
				var load = data[0].split('=');
				var dest = data[1].split('=');
				var date = data[2].split('=');
				var date = new Date(formatDate(date[1]));
				
				var value1 = 'load-port-'+load[1].toString().toLowerCase();
				var value2 = 'destination-port-'+dest[1].toString().toLowerCase();
				
				for (var i = 0; i < table.children.length; i++) {
					var itemDate = new Date( formatDate(table.children[i].dataset.date) );
					if( table.children[i].className.includes(value1) & table.children[i].className.includes(value2) & itemDate >= date) {
						table.children[i].style.display = 'table-row';
					}
				}
		break;
		case 'destdate':

				var load = data[0].split('=');
				var date = data[1].split('=')
				var date = new Date(formatDate(date[1]));
				var value = 'destination-port-'+load[1].toString().toLowerCase();
				for (var i = 0; i < table.children.length; i++) {
					var itemDate = new Date( formatDate(table.children[i].dataset.date) );
					if( table.children[i].className.includes(value) & itemDate >= date) {
						table.children[i].style.display = 'table-row';
					}
				}
		break;
	}
}



function childPortTrigger(target, child) {
	
	$(child).attr("disabled", true);
	if(target !== null ) {

		
		target.addEventListener('change', function(e) {
			var value = e.target.value;
			$.ajax({
				type : "post",
				dataType : "json",
				url : window.location.origin+'/wp-admin/admin-ajax.php',
				data : {action: "get_related_ports", value : value },
				error: function(response) {
					// console.log('error');
					$(child).attr("disabled", true);
				},
				success: function(response) {
					// console.log('Success');
					var optionsOld = $(child).find('option');
					
					$(optionsOld).each( function(e) {
						$(this).remove();
					});
					
					for (var index = 0; index < response.length; index++) {
						console.log(response[index].post_title);
						$(child).append('<option value="'+response[index].post_title+'" >'+response[index].post_title+'</option>');
					}
					$(child).attr("disabled", false);
				}
			}); 
		});
	}
}

(function($) {
	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var app = {
		isMobile: function() {
			return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
		},
		hasLodash: (typeof lodash == 'function'),
	};

	var Sage = {
		// All pages
		common: {
			init: function() {
				
				$('#schedule-print').on('click', function(e) {
					printSection();
				} );

				$('#choice_6_82_0').on('mousedown', function(e) {
					
				});

				// $('.disable-this').prop( "disabled", true );

				// var disablethings = document.querySelectorAll('.disable-this');
				// for (var index = 0; index < disablethings.length; index++) {
				// 	var element = disablethings[index];
				// 	element.disabled = true;
					
				// }

				$('.hive-login__trigger-wrap').on('click', function(e){
					$(this).siblings('.hiveform--wrapper').toggle();
				});
				
				$('.close-login-modal').on('click', function(e) {
					$(this).parents('.hiveform--wrapper').hide();

				});

				$('.fancy-anchor').on('click', function(e){
					$anchorAttr = $(this).attr("href");
					$section = "section";

					$('html, body').animate({ scrollTop: $($section + $anchorAttr).offset().top - 200 },'slow');
				});

				// console.log(window.location.origin); 
				
				document.getElementById('quote-request-button').addEventListener('click', function() {
					if(document.body.classList.contains('quote-form-active')) {
						document.body.classList.remove('quote-form-active');
						$('#site--wrapper').unbind('click');
					} else {
						document.body.classList.add('quote-form-active');
					}
				});


				$('.js-cta--quote-request-button').on('click', function(e) {
					if(document.body.classList.contains('quote-form-active')) {
						document.body.classList.remove('quote-form-active');
						$('#site--wrapper').unbind('click');
					} else {
						document.body.classList.add('quote-form-active');
					}
				});

				// Listen to tab events to enable outlines (accessibility improvement)
				document.body.addEventListener('keyup', function(e) {
					if (e.which === 9) /* tab */ {
						document.documentElement.classList.remove('no-focus-outline');
					}
				});

				$('#choice_11_1_0').on('change', function(e) {
					$(this).parent('li').siblings('li').removeClass('active');
					if( e.currentTarget.checked === true ) {
						$(this).parent('li').addClass('active');
					}
					
				});
				$('#choice_11_1_1').on('change', function(e) {
					$(this).parent('li').siblings('li').removeClass('active');
					if( e.currentTarget.checked === true ) {
						$(this).parent('li').addClass('active');
					}
				});
				$('#choice_11_16_0').on('change', function(e) {
					$(this).parent('li').siblings('li').removeClass('active');
					if( e.currentTarget.checked === true ) {
						$(this).parent('li').addClass('active');
					}
					
				});
				$('#choice_11_16_1').on('change', function(e) {
					$(this).parent('li').siblings('li').removeClass('active');
					if( e.currentTarget.checked === true ) {
						$(this).parent('li').addClass('active');
					}
				});

				$('#choice_10_1_0').on('change', function(e) {
					$(this).parent('li').siblings('li').removeClass('active');
					if( e.currentTarget.checked === true ) {
						$(this).parent('li').addClass('active');
					}
					
				});
				$('#choice_10_1_1').on('change', function(e) {
					$(this).parent('li').siblings('li').removeClass('active');
					if( e.currentTarget.checked === true ) {
						$(this).parent('li').addClass('active');
					}
				});
				$('#choice_10_16_0').on('change', function(e) {
					$(this).parent('li').siblings('li').removeClass('active');
					if( e.currentTarget.checked === true ) {
						$(this).parent('li').addClass('active');
					}
					
				});
				$('#choice_10_16_1').on('change', function(e) {
					$(this).parent('li').siblings('li').removeClass('active');
					if( e.currentTarget.checked === true ) {
						$(this).parent('li').addClass('active');
					}
				});

				$(".quote-toggler").click(function(e) {
					e.preventDefault();

					if(document.body.classList.contains('quote-form-active')) {
						document.body.classList.remove('quote-form-active');
					} else {
						document.body.classList.add('quote-form-active');
						$('.seabridge-quote__form').focus();

						$('.seabridge-quote__form').on('focusout', function() {
							document.body.classList.remove('quote-form-active');
						});
					}

				});

				$('.gf-default-disabled').each( function(e) {
					console.log( $(this) );
					$(this).attr('disabled', false);
				});
			
				$(document).on('gform_page_loaded', function(event, form_id, current_page) {

					disableUtil();

					// Define the items that need country logic
					var data = document.getElementById('input_6_49');
					var child = document.getElementById('input_6_50');
					childPortTrigger(data , child); 

					childPortTrigger(
						document.getElementById('input_6_44'),
						document.getElementById('input_6_68')
					)

					childPortTrigger(
						document.getElementById('input_11_82'),
						document.getElementById('input_11_80')
					);

					childPortTrigger(
						document.getElementById('input_11_81'),
						document.getElementById('input_11_83')
					);

					

					$('.trigger-get-a-quote').on('click', function(e) {
						e.preventDefault();
						$('#quote-request-button').click();
					});

					
						

				});

				if(app.isMobile()) {
					document.addEventListener('scroll', function() {
						if($(window).scrollTop() + 100 > $(document).height() - $(window).height() ) {
							$('#quote-request-button').hide(250);
						} else {
							if(!$('#quote-request-button').is(':visible')) {
								$('#quote-request-button').show(250);
							}
						}
					}, { capture: true, passive: true });
				}

				$('a[data-action*="scroll"]').on('click', function(e) {
					e.preventDefault();
					var loc = $(this).attr('href');

					if(document.body.classList.contains('single-services')) {
						if(!app.isMobile()) {
							$('html, body').animate({
								scrollTop: $(loc).offset().top - 200,
							}, 1000);
						} else {
							$('html, body').animate({
								scrollTop: $(loc).offset().top,
							}, 1000);
						}
					} else {
						$('html, body').animate({
							scrollTop: $(loc).offset().top,
						}, 750);
					}
				});

				$('.video-modal-trigger').modalVideo();

				if(app.hasLodash && !app.isMobile()) {
					var _ = lodash;

					var megaMenu = {
						active: false,
						trigger: document.getElementById('sticky-toggle'),
						topBoundary: null,
						bottomBoundary: null,
					};

					megaMenu.checkBoundaries = function(instance) {
						var conditionA = window.pageYOffset <= instance.bottomBoundary;
						var conditionB = window.pageYOffset >= instance.topBoundary;

						if(conditionA || conditionB) {
							instance.deactivate();
						} 
					}

					megaMenu.activate = function() {
						// Trigger Active Class
						document.body.classList.add('megamenu-active');
						megaMenu.active = true;

						// Define Boundaries
						this.topBoundary = window.pageYOffset + 100;
						this.bottomBoundary = window.pageYOffset - 100;

						var self = this;

						// Initiate Scroll Listener
						document.addEventListener('scroll', _.throttle(_.partial(this.checkBoundaries, self), 1000), { capture: true, passive: true });
					}

					megaMenu.deactivate = function() {
						document.body.classList.remove('megamenu-active');
						this.active = false;
						megaMenu.topBoundary = null;
						megaMenu.bottomBoundary = null;
					}

					// Megamenu trigger listener + event
					megaMenu.trigger.addEventListener('click', function() { 
						if(megaMenu.active) {
							megaMenu.deactivate();
						} else {
							megaMenu.activate();
						}
					});
				}

				// Toggles body classes defined by the offset
				function stickyMenuHandler() {
					var sticky_class = "desktop-sticky-active";
					var offset = 100;
					var classList = document.body.classList;
					var megamenuTransition = 350;

					if(window.pageYOffset>= offset) {
						if( !classList.contains(sticky_class) ) { classList.toggle(sticky_class); }
					} else if( classList.contains(sticky_class) ) {
						if( classList.contains('megamenu-active') ) {
							megaMenu.deactivate();
							setTimeout(function() {
								classList.remove(sticky_class);
							}, megamenuTransition, classList);
						} else {
							document.body.classList.remove(sticky_class);
						}
					}
				}

				document.addEventListener('scroll', stickyMenuHandler, {capture: true, passive: true});
					
				/* ========================================================================
				 * Remove preload body class
				 * ======================================================================== */
				setTimeout(function() { document.body.classList.remove('preload'); }, 500);

				/* ========================================================================
				 * Style all selects
				 * ======================================================================== */

				$('select').each( function(e) {
					var target = $(this).parents('li').hasClass('no-bs');
					if( target == false && $(this).hasClass('prevent_bs') == false ) {
						$(this).selectpicker({
							dropupAuto: false
						});
					}
				})


				/**
				 * Header search button click action
				 */
				$(".header-search__button").click(function() {
					$("body").toggleClass("search-active");
					$(".search-form-wrap").slideToggle(250);
				});

				$(".feature-gallery-slider").slick({
					slidesToShow: 1,
					arrows: true,
					dots: false,
					slidesToScroll: 1,
					prevArrow:
						'<button type="button" data-role="none" class="slick-prev slick-arrow" aria-label="Previous" role="button" style="display: block;"><i class="fal fa-angle-left"></i></button>',
					nextArrow:
						'<button type="button" data-role="none" class="slick-next slick-arrow" aria-label="Next" role="button" style="display: block;"><i class="fal fa-angle-right"></i></button>'
				});

				var scrollOffset = 150;
				var doc = $(document);
				var body = $("body");

				$(".faq-trigger").on("click", function(e) {
					e.preventDefault();
					var el = $(this).closest(".faq");
					el.toggleClass("active");
					el.find(".bottom").slideToggle(500);
				});

				/**
				 * Load Gallery Lightboxes
				 */
				// $("a.fl-gallery").featherlightGallery({
				// 	previousIcon: "&#9664;" /* Code that is used as previous icon */,
				// 	nextIcon: "&#9654;" /* Code that is used as next icon */,
				// 	galleryFadeIn: 100 /* fadeIn speed when slide is loaded */,
				// 	galleryFadeOut: 300 /* fadeOut speed before slide is loaded */
				// });

				$(".pb--slider").owlCarousel({
					items: 1,
					nav: true
				});

				/* ========================================================================
				 *
				 * Form Section Toggles
				 *
				 * The below function handle the form switching and initial load events
				 * for the multi step forms featured on the home page and other pages.
				 *
				 * ======================================================================== */

				/* ========================================================================
				 * Global Elements
				 * ======================================================================== */
				var form_action_nav_row = $(".form-action-bar__nav-row");

				/* ========================================================================
				 * Window Load Event Handler
				 * ======================================================================== */
				$(window).ready(function() {
					// The .init-toggle class applied to a trigger defines the first form to
					// become active, so here we're just getting that.
					var initial_trigger = $(".form-section__trigger.init-toggle");

					// Sections are defined by the data-section-toggle attribute.
					var initial_section_label = initial_trigger.attr(
						"data-section-toggle"
					);

					// Select the initial section
					var initial_section = $(
						'.form-section[data-section="' + initial_section_label + '"]'
					);

					// Perform the toggle
					formActionToggle(initial_section);

					var init_is_desktop_trigger = initial_trigger.hasClass("desktop");

					if (init_is_desktop_trigger) {
						initial_trigger.addClass("active");
					}
				});

				/* ========================================================================
				 * Toggle Button Clicks
				 * ======================================================================== */
				$(".form-section__trigger").click(function() {
					// Retrieve the section label
					var section_label = $(this).attr("data-section-toggle");

					// Select the toggle section
					var toggle_section = $(
						'.form-section[data-section="' + section_label + '"]'
					);

					var is_desktop_trigger = $(this).hasClass("desktop");

					var is_outbound_link = $(this).hasClass("outbound");

					console.log(is_outbound_link);

					if (is_outbound_link) {
						// Perform the toggle
						// Don't do anything
					} else if (!is_desktop_trigger) {
						// Perform the toggle
						// Dont pass active
						// Check the header gap
						formActionToggle(toggle_section, false, true);
					} else {
						// Perform the toggle
						formActionToggle(toggle_section);
					}

					var already_active_trigger = $(this).hasClass("active");

					if (!already_active_trigger && is_desktop_trigger && is_outbound_link != true) {
						$(".form-section__trigger.active").removeClass("active");
						$(this).addClass("active");
					}
				});

				/**
				 * formActionToggle
				 *
				 * @param section {type}          HTMLelement
				 * @param passActive {type}       bool
				 * @param check_header_gap {type} bool
				 * @returns {undefined}
				 */
				function formActionToggle(section, passActive, check_header_gap) {

					/* ========================================================================
					 * Default Parameter Checking
					 * ======================================================================== */
					toggle_section = section || false;
					passActive = passActive || false;
					check_header_gap = check_header_gap ? true : false;

					// If there's no section to toggle, return.
					if (!toggle_section) {
						return;
					}

					/* ========================================================================
					 * Declare utility variables
					 * ======================================================================== */
					var is_section_active = toggle_section.hasClass("active");

					// var is_outbound_link = toggle_section.hasClass("outbound");

					// console.log('Is Outbound Link: ', is_outbound_link);

					/* ========================================================================
					 *
					 * Active Conditional Check:
					 *
					 * We need to check if the section they've clicked already
					 * has an active class applied as we don't want to slide up
					 * and down for no reason, additionally on page load, this
					 * initial section won't have the class, so we just have the
					 * passActive argument to allow this that first time.
					 * ======================================================================== */
					if (!is_section_active || passActive == true) {
						var current_active_section = $(".form-section.active");
						var active_section_inner = current_active_section.find(
							".form-section__inner"
						);

						/* ========================================================================
						 * - Slide active sections form wrapper up
						 * - Remove active class from that section.
						 * ======================================================================== */
						active_section_inner.slideUp(0);
						current_active_section.removeClass("active");

						/* ========================================================================
						 * - Retrieve new sections form wrapper
						 * - Apply active class to new section
						 * - Slide new sections form wrapper down
						 * ======================================================================== */
						var toggle_section_inner = section.find(".form-section__inner");

						section.addClass("active");
						toggle_section_inner.slideDown(0);

						/* ========================================================================
						 * - Apply a check to see if we need to apply spacing.
						 * ======================================================================== */
						if (check_header_gap) {
							setTimeout(function() {
								checkPageHeaderGap();
							}, 525);
						}
					}
				}

				var ready_to_check = false;

				$(window).on("resize", function() {
					if (!ready_to_check) {
						ready_to_check = true;

						setTimeout(function() {
							checkPageHeaderGap();
						}, 250);
					}
				});

				checkPageHeaderGap();

				function checkPageHeaderGap() {
					var formBlock = $(".form-action-bar-block");
					var _formBlockHeight = formBlock.outerHeight(true);
					var page_introduction = $(".front-page-introduction");

					if (form_action_nav_row.is(":visible")) {
						if (parseInt(page_introduction.css("padding-top")) !== 109) {
							page_introduction.css("padding-top", "109px");
						}
					} else {
						var offset = 280;
						var _offsetCalc = _formBlockHeight - offset;
						var _offsetMargin = parseInt(
							page_introduction.css("padding-top"),
							10
						);

						if (_offsetCalc > _offsetMargin) {
							page_introduction.css("padding-top", _offsetCalc + 43 + "px");
						} else if (_offsetMargin > _offsetCalc + 15) {
							page_introduction.css("padding-top", _offsetCalc + 43 + "px");
						}
					}

					ready_to_check = false;
				}

				/* ========================================================================
					* Card Trigger Toggles
					* ======================================================================== */
					$('.card--toggle__button').click(function() {
						$(this).closest(".card--toggle").toggleClass('active').find('.card--toggle__wrapper').slideToggle(250);
					});

				/* ========================================================================
					* Content Slider
					* ======================================================================== */
				var content_slider = $('.content-slider').owlCarousel({
					items: 1,
					loop: true,
					dotsContainer: '#content-slider__dots',
					rewind: false,
					dots: true,
					autoHeight: true
				});

				$('.single-card-slider__button').click(function() {
					content_slider.trigger($(this).attr('aria-label') + '.owl.carousel');
				});

				var gal = $('.gallery-slider').owlCarousel({
					items: 1,
					dots: false,
					loop: true,
					dotsContainer: '.gallery-slider__dots',
					rewind: false,
					dots: true,
					arrows: false
				});

				$('.gallery-slider__trigger').click(function() {
					gal.trigger($(this).attr('js-action') + ".owl.carousel");
				});

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		home: {
			init: function() {
				var hero_slider = $(".hero-slider");

				// JavaScript to be fired on the home page
				hero_slider.slick({
					items: 1,
					dots: false,
					speed: 1000,
					arrows: true,
					adaptiveHeight: true,
					autoplay: true,
					autoplaySpeed: 7000,
					fade: true,
					prevArrow:
						"<button type='button' class='slick-prev disable-button-styles'><i class='fal fa-angle-left' aria-hidden='true'></i></button>",
					nextArrow:
						"<button type='button' class='slick-next disable-button-styles'><i class='fal fa-angle-right' aria-hidden='true'></i></button>"
				});

				/**
				 * Due to adaptive height on the slider, if the slider keeps running
				 * while we're scrolling down the page is constantly re
				 * arranging itself, so let's stop that.
				 */
				var waypoint = new Waypoint({
					element: document.getElementsByClassName("page-header")[0],
					handler: function(direction) {
						if (direction == "up") {
							hero_slider.slick("slickPlay");
						} else {
							hero_slider.slick("slickPause");
						}
					},
					offset: function() {
						return -this.element.clientHeight;
					}
				});
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		blog: {
			init: function() {
				$(".post-slider").each(function() {
					$(this).owlCarousel({
						responsive: {
							0 : {
								items: 1.1,
								margin: 15,
								loop: true,
								dots: true,
								autoHeight: true,
								dotsContainer: '#post-slider__dots',
								rewind: false,
								checkVisibility: true
							},
							// Breakpoints from 768 up
							768 : {
								items: 2,
								autoHeight: true,
								dotsContainer: '#post-slider__dots'
							}
						}
					});
				});

				$('.post-slider__button').click(function() {
					var label = $(this).attr('aria-label');

					$('.post-slider').trigger(label + '.owl.carousel');
				})
			}
		},
		single_post: {
			init: function() {
				$('.single-card-slider').owlCarousel({
					items: 1,
					loop: true,
					dotsContainer: '#single-card-slider__dots',
					rewind: false,
					dots: true,
					autoHeight: true
				});
			}
		},
		page_template_template_container_specifications: {
			init: function() {

				$('a[href^="#"]').on('click', function(event) {
					
					$('a[href^="#"]').each(function(e) {
						$(this).removeClass('active');
					});
					var target = $(this.getAttribute('href'));
					if( target.length ) {
						event.preventDefault();
						$(this).addClass('active')
						$('html, body').stop().animate({
							scrollTop: target.offset().top - 200
						}, 1000);
					}
				});
			}
		},
		single_services: {
			init: function() {
				try {
					if ( typeof mixitup !== 'function' ) {
						throw "Mixitup library not found. Expected type function for mixitup, received: " + typeof mixitup + ".";
					}

					var mixer = mixitup(".blocks-swaps-wrapper", {
						load: {
							filter: ".details-block-1"
						},
						callbacks: {
							onMixClick: function(state, futureState) {
								if(app.isMobile()) {
									$([document.documentElement, document.body]).animate({
										scrollTop: $('#block-swaps').offset().top
									}, 500);
								}
							}
						}
					});

				} catch (error) {
					console.error(error);
				}
			}
		},
		single_locations: {
			init: function() {
				try {
					var mapEl = document.getElementById('map');
					var markerEl = mapEl.getElementsByClassName('marker')[0]; 

					mapboxgl.accessToken = 'pk.eyJ1IjoiYXByaWwtc3R1ZGlvIiwiYSI6ImNqeHdxMXFmdjBmZXkzaG84YXZkd2pmb3UifQ.XYdw2_RLMSww5OibVRL0bA'; 

					if(mapEl !== null) {
						// Map element found 
						// var lat = markerEl.getAttribute('data-lat');
						// var lng = markerEl.getAttribute('data-lng');
						var lat = markerEl.dataset.lat;
						var lng = markerEl.dataset.lng;
						var mapArgs = {
							container: 'map',
							style: "mapbox://styles/april-studio/ck0yjjlv70fyq1crratbnc3ie",
							center: [lng, lat],
							zoom: 13
						};

						var values = {
							'lat': lat, 'lng': lng
						}

						var mapInstance = new mapboxgl.Map(mapArgs)

						var el = document.createElement('i');
						el.className = 'fa-4x fas fa-map-marker-alt map-marker-black'; 
						  
						new mapboxgl.Marker(el)
    					.setLngLat(values)
						.addTo(mapInstance);
					} 
				}
				catch(error) {
					console.error(error);
				}
			}
		},
		page_template_template_sailing_schedule: {
			init: function() {

				onloadFilter();

				var mixerContainer = $('#schedules-container');
				var loadportFilter = $('#load-port-select');
				var destinationFilter = $('#destination-select');

				$('body.sailing-schedule').find('form#schedule-form').addClass('d-hidden');
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = funcname === undefined ? "init" : funcname;
			fire = func !== "";
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === "function";

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire("common");

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, "_").split(/\s+/), function(
				i,
				classnm
			) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, "finalize");
			});

			// Fire common finalize JS
			UTIL.fire("common", "finalize");
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);
})(jQuery, window); // Fully reference jQuery after this point.
